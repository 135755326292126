import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, useAnimation, transform } from "framer-motion";
import * as LR from '@uploadcare/blocks';
import './App.css';

LR.registerBlocks(LR);

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

const SearchResult = ({ id, removeSearchResult }) => {
  // State for each editable part of the search result
  const [faviconUrl, setFaviconUrl] = useState('https://i.kinja-img.com/image/upload/c_fill,h_80,q_80,w_80/eti2h1r4wg0bqxctxenl.png');
  const dataOutputRef = useRef();
  const uploaderContainerRef = useRef(); // Ref for the uploader container
  const [siteName, setSiteName] = useState('The Onion');
  const [breadcrumbs, setBreadcrumbs] = useState('https://www.theonion.com › nerf-introduces-line-of-re...');
  const [title, setTitle] = useState('Nerf Introduces Line Of Real Guns');
  const [description, setDescription] = useState('“We cannot emphasize enough that these are not toys,” said CEO Mark Ridley, noting that newly released products such as the .44 Nerf Magnum ...');
  const titleRef = useRef(null); // Ref for the title input
  const maxLength = 70; // Set the max length in characters for the title
  const [titleWidth, setTitleWidth] = useState(0); // State to store the pixel width of the title
  const measureRef = useRef(null); // Ref for the measuring div
  const maxPixelWidth = 600; // Set the max pixel width for the title
  const pixelsRemaining = maxPixelWidth - titleWidth;
  const charactersRemaining = maxLength - title.length;
  const [descriptionWidth, setDescriptionWidth] = useState(0);
  const descriptionMeasureRef = useRef(null); // Ref for the description measuring div
  const maxDescriptionPixelWidth = 960; // Set the max pixel width for the description
  const descriptionPixelsRemaining = maxDescriptionPixelWidth - descriptionWidth;
  const controls = useAnimation();
  const mapRemainingToColor = transform([2, 6], ["#ff008c", "#ccc"]);
  const mapRemainingToSpringVelocity = transform([0, 5], [50, 0]);
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
  const date = new Date();
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  const [showStars, setShowStars] = useState(false);

  const handleTitleCaseConversion = () => {
    const titleCased = toTitleCase(title);
    setTitle(titleCased);
  };

  const addDate = () => {
    // Define the prefix to check against
    const datePrefix = `${formattedDate} - `;
  
    // Check if the description starts with the date prefix
    if (description.startsWith(datePrefix)) {
      // If so, remove the date prefix from the description
      setDescription(description.substring(datePrefix.length));
    } else {
      // If not, add the date prefix to the front of the description
      setDescription(`${datePrefix}${description}`);
    }
  };

  const addStars = () => {
    setShowStars(!showStars);
  };  

  // Start the countdown animation when the title pixel width changes
  useEffect(() => {
    const pixelVelocity = transform([0, pixelsRemaining], [50, 0])(titleWidth);
  
    controls.start({
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        velocity: pixelVelocity,
        stiffness: 700,
        damping: 80
      }
    });
  
    // Conditionally fade out based on focus
    if (!isTitleFocused) {
      const timeout = setTimeout(() => {
        controls.start({ opacity: 0 });
      }, 2000);
  
      return () => clearTimeout(timeout);
    }
  }, [titleWidth, isTitleFocused]); // Add isTitleFocused as a dependency  

  // Start the countdown animation when the description pixel width changes
  useEffect(() => {
    const pixelVelocity = transform([0, descriptionPixelsRemaining], [50, 0])(descriptionWidth);
  
    controls.start({
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        velocity: pixelVelocity,
        stiffness: 700,
        damping: 80
      }
    });
  
    // Conditionally fade out based on focus
    if (!isDescriptionFocused) {
      const timeout = setTimeout(() => {
        controls.start({ opacity: 0 });
      }, 2000);
  
      return () => clearTimeout(timeout);
    }
  }, [descriptionWidth, isDescriptionFocused]); // Add isDescriptionFocused as a dependency  

  // Update the pixel width in state each time the title changes
  useEffect(() => {
    if (measureRef.current) {
      const width = measureRef.current.offsetWidth;
      setTitleWidth(width); // Update the width state
    }
  }, [title]); // Dependency on title ensures this runs when title changes

  // Update the pixel width in state each time the description changes
  useEffect(() => {
    if (descriptionMeasureRef.current) {
      const width = descriptionMeasureRef.current.offsetWidth;
      setDescriptionWidth(width); // Update the width state
    }
  }, [description]); // Dependency on description ensures this runs when description changes

  // Handlers for focus and blur events
  const handleTitleFocus = () => setIsTitleFocused(true);
  const handleTitleBlur = () => setIsTitleFocused(false);
  const handleDescriptionFocus = () => setIsDescriptionFocused(true);
  const handleDescriptionBlur = () => setIsDescriptionFocused(false);

  // Handlers for change events on each input
  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  // Handler to be called when an image is uploaded
  const handleFaviconUpload = useCallback((e) => {
    const { data } = e.detail;
    if (data.length > 0) {
      setFaviconUrl(data[0].cdnUrl);
    }
  }, []);

  useEffect(() => {
    const el = dataOutputRef.current;
    el?.addEventListener('lr-data-output', handleFaviconUpload);
    return () => {
      el?.removeEventListener('lr-data-output', handleFaviconUpload);
    };
  }, [handleFaviconUpload]);

  return (
    <div className="search-result">
      <div className="search-result-header">
        <div className="favicon" ref={uploaderContainerRef}>
            {faviconUrl ? (
              <img src={faviconUrl} alt="Favicon" />
            ) : (
              <img src="./default.svg" alt="Default Favicon" />
            )}
            <div className="uploader-hover">
              <lr-config
                ctx-name="my-uploader"
                pubkey="840203f98f6fb7a633e8" // Replace with your public API key
                source-list="local,url,dropbox,gdrive"
              />
              <lr-file-uploader-regular
                ctx-name="my-uploader"
                class="my-locale-override"
                css-src={`https://unpkg.com/@uploadcare/blocks@0.30.9/web/lr-file-uploader-regular.min.css`}
              >
                <lr-data-output ctx-name="my-uploader" ref={dataOutputRef} use-event hidden></lr-data-output>
              </lr-file-uploader-regular>
            </div>
        </div>
        <div className="header-texts">
          <input 
            className="site-name" 
            value={siteName} 
            onChange={handleChange(setSiteName)} 
          />
          <input 
            className="breadcrumbs" 
            value={breadcrumbs} 
            onChange={handleChange(setBreadcrumbs)} 
          />
        </div>
        <div className="stars-button" onClick={addStars}>
          <img src="https://metadata-sbf3.onrender.com/stars.svg" alt="Add star ratings" title="Add ratings" />
        </div>
        <div className="date-button" onClick={addDate}>
          <img src="https://metadata-sbf3.onrender.com/calendar.svg" alt="Add the date" title="Add the date" />
        </div>
        <div className="title-case-button" onClick={handleTitleCaseConversion}>
          <img src="https://metadata-sbf3.onrender.com/titlecase.svg" alt="Convert to title case" title="Convert to title case" />
        </div>
        <div className="remove-button" onClick={removeSearchResult}>X</div>
      </div>
      <input 
      ref={titleRef}
      className="title" 
      value={title} 
      maxLength={maxLength} // Set the maxLength for validation
      onFocus={handleTitleFocus}
      onBlur={handleTitleBlur}
      onChange={handleChange(setTitle)}
      />
      {/* Hidden div to measure text width */}
      <div ref={measureRef} className="measure" aria-hidden="true">
        {title}
      </div>
      {/* Hidden div to measure text width for the description */}
      <div ref={descriptionMeasureRef} className="measure description-measure" aria-hidden="true">
        {description}
      </div>
      {isTitleFocused && (
        <motion.span
          className="character-countdown"
          animate={controls}
          style={{ color: mapRemainingToColor(pixelsRemaining) }}
        >
          {pixelsRemaining}px
        </motion.span>
      )}
      {/* Additional countdown for the description */}
      {isDescriptionFocused && (
        <motion.span
          className="description-countdown"
          animate={controls}
          style={{ color: mapRemainingToColor(descriptionPixelsRemaining) }}
        >
          {descriptionPixelsRemaining}px
        </motion.span>
      )}
      <textarea 
      className="description" 
      value={description} 
      onFocus={handleDescriptionFocus}
      onBlur={handleDescriptionBlur}
      onChange={handleChange(setDescription)} 
      />
      {showStars && (
        <div className="uo4vr">
          <span aria-hidden="true" class="stars" aria-label="Rated 4.5 out of 5," role="img">
            <span style={{width: `calc(calc(12px + 2px) * 4 + calc(12px / 2) * 1)`}}></span>
          </span>
          <span>Rating: 4.5</span> · &lrm; 
          <span>62 reviews</span> · &lrm; 
          <span>$98.00</span> · &lrm; 
          <span>30-day returns</span>
        </div>
      )}
    </div>
  );
  };

  const TipsSection = () => {
    return (
      <div className="tips-container">
        <h2 style={{ textAlign: 'center' }}>Writing Tips</h2>
        <div className="tip-card">
          <div className="icon-container keyword-icon"></div>
          <div>
            <h3>Include keywords in the title</h3>
            <p>Use Google Keyword Planner to find how people search for the topic of your webpage or Google Search Console to see what keywords it's already ranking for. Naturally incorporate those keywords into the title.</p>
          </div>
        </div>
        <div className="tip-card">
          <div className="icon-container build-icon"></div>
          <div>
            <h3>Build upon - rather than repeat - what's in the title for the meta description</h3>
            <p>Get specific in terms of what to expect once they click through.</p>
          </div>
        </div>
        <div className="tip-card">
          <div className="icon-container limit-icon"></div>
          <div>
            <h3>Stay within length limits</h3>
            <p>There's no hard limit for character count. Instead Google looks at the pixel width of each character. Look at the pixel countdown that appears as you type.</p>
          </div>
        </div>
        <div className="hands-on-help">
          <p><small>Brought to you by</small></p>
        <a href="https://www.cogney.com.hk"><img style={{ height: '10px' }} src="./cogney.svg" alt="Cogney" /></a>
        </div>
      </div>
    );
  };
  

      const App = () => {
        const [searchResults, setSearchResults] = useState([
          { id: 1 }, // Initial search result
        ]);
        const uniqueId = () => Math.floor(Math.random() * Date.now());
      
        const addSearchResult = () => {
          setSearchResults([...searchResults, { id: uniqueId() }]);
        };
      
        const removeSearchResult = (id) => {
          setSearchResults(searchResults.filter(result => result.id !== id));
        };

        return (
          <div className="App">
            <h1>Google Snippet Preview</h1>
            <p>Click any part of the snippet to edit it.</p>
            {searchResults.map(result => (
              <SearchResult
                key={result.id}
                id={result.id}
                removeSearchResult={() => removeSearchResult(result.id)}
              />
            ))}
            <button onClick={addSearchResult} className="add-search-result-button">
              + Add New
            </button>
            <TipsSection />
          </div>
        );
      };

export default App;
